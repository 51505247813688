import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import FormEmail from "./components/formEmail";

const Signup = () => {
    return (
        <div>
            <Container fluid className={"d-flex px-0 text-center align-items-center"}
                       style={{height: 'calc(100vh - 101.5px)'}}>
                <Row className={"w-100 justify-content-center m-0"}>
                    <Col xs={12} sm={7} md={6} lg={5} xl={4} xxl={3}>
                        <FormEmail/>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Signup;
