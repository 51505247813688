import React from "react";
import {NavLink, OverlayTrigger, Tooltip} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {openOffcanvas} from "../../../actions/dashboardActions";

const ButtonAdministration = () => {
    const isOffcanvasOpen = useSelector(state => state.offcanvas.isOffcanvasOpen);
    const dispatch = useDispatch();

    const handleOpenOffcanvas = () => {
        dispatch(openOffcanvas());
    };
    return (
        <NavLink onClick={handleOpenOffcanvas} className={"px-lg-1"}>
            <OverlayTrigger placement={"auto"} overlay={<Tooltip>Acesso á adminitração</Tooltip>}>
                <span><i className={"fs-5 text-primary ps-lg-1 bi bi-gear"}></i> <span
                    className={"fs-6"}>Administração</span></span>
            </OverlayTrigger>
        </NavLink>
    );
}

export default ButtonAdministration;