import React from 'react';

import CryptoJS from "crypto-js";
const hash = (hash,input)=>{
    if(hash == "sha256"){
        return CryptoJS.SHA256(input).toString(CryptoJS.enc.Hex);
    }
    return input;
}

export default hash;