import * as React from "react";
import {Col, Container, ListGroup, Row} from "react-bootstrap";
import FormProfile from "./formProfile";
import FormDocuments from "./formDocuments";
import FormAccountLevel from "./formAccountLevel";
import FormAddress from "./formAddress";
import {useState} from "react";

const PageProfile = () => {
    const [activeForm, setActiveForm] = useState("profile");

    return (
        <Row className={"p-0 m-0"}>
            <Col className={"p-0 pe-0 pe-md-3 m-0 mb-3 mb-md-0"} md={"3"}>
                <ListGroup className={"shadow-sm"}>
                    <ListGroup.Item action active={activeForm === "profile"}
                                    onClick={() => setActiveForm("profile")}>
                        <i className={"fs-5 bi bi-person"}/> Perfil
                    </ListGroup.Item>
                    <ListGroup.Item action active={activeForm === "address"}
                                    onClick={() => setActiveForm("address")}>
                        <i className={"fs-5 bi bi-geo-alt"}/> Endereço
                    </ListGroup.Item>
                    <ListGroup.Item action active={activeForm === "documents"}
                                    onClick={() => setActiveForm("documents")}>
                        <i className={"fs-5 bi bi-file-richtext"}/> Documentos
                    </ListGroup.Item>
                    <ListGroup.Item action active={activeForm === "accountLevel"}
                                    onClick={() => setActiveForm("accountLevel")}>
                        <i className={"fs-5 bi bi-diagram-3"}/> Nivel da conta
                    </ListGroup.Item>
                </ListGroup>
            </Col>
            <Col className={"p-0 m-0"} md={"9"}>
                {activeForm === "profile" && <FormProfile/>}
                {activeForm === "address" && <FormAddress/>}
                {activeForm === "documents" && <FormDocuments/>}
                {activeForm === "accountLevel" && <FormAccountLevel/>}
            </Col>
        </Row>
    )
}

export default PageProfile;