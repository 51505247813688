import React, {useEffect} from "react";
import {Button} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {setTheme} from "../../../actions/themeActions";

const ButtonTheme = ()=>{
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.theme.theme);

    useEffect(() => {
        document.documentElement.setAttribute('data-bs-theme', theme);
    }, [theme]);

    const toggleTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        dispatch(setTheme(newTheme));
    };

    return (
        <div>
            <Button variant={theme=="light"?"light":"dark"} onClick={toggleTheme} className={"me-3 py-0 px-1 text-primary rounded-circle"}>
                {theme == "light" ? <i className="bi bi-brightness-high"></i> :
                    <i className="bi bi-moon"></i>}
            </Button>
        </div>
    );
}

export default ButtonTheme;