// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-toggle-white-hover {
  color: #fff !important;
}

.dropdown-toggle-white-hover:hover,
.dropdown-toggle-white-hover:focus,
.dropdown-toggle-white-hover:active {
  color: #fff !important;
  background-color: #007bff !important;
}

.dropdown-toggle-white-hover .icon-hover-white {
  color: #fff !important;
}

.dropdown-toggle-white-hover:hover .icon-hover-white,
.dropdown-toggle-white-hover:focus .icon-hover-white,
.dropdown-toggle-white-hover:active .icon-hover-white {
  color: #fff !important;
}

.custom-scroll::-webkit-scrollbar {
  width: 8px; /* Largura da barra */
}

.custom-scroll::-webkit-scrollbar-track {
  background: #f1f1f1; /* Cor de fundo do trilho */
  border-radius: 4px; /* Borda arredondada */
}

/* Cor e estilo da barra */
.custom-scroll::-webkit-scrollbar-thumb {
  background: #007bff; /* Cor da barra */
  border-radius: 4px;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #0056b3; /* Cor da barra ao passar o mouse */
}

/* Firefox */
.custom-scroll {
  scrollbar-width: thin;
  scrollbar-color: #007bff #f1f1f1;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;;;EAGE,sBAAsB;EACtB,oCAAoC;AACtC;;AAEA;EACE,sBAAsB;AACxB;;AAEA;;;EAGE,sBAAsB;AACxB;;AAEA;EACE,UAAU,EAAE,qBAAqB;AACnC;;AAEA;EACE,mBAAmB,EAAE,2BAA2B;EAChD,kBAAkB,EAAE,sBAAsB;AAC5C;;AAEA,0BAA0B;AAC1B;EACE,mBAAmB,EAAE,iBAAiB;EACtC,kBAAkB;AACpB;;AAEA;EACE,mBAAmB,EAAE,mCAAmC;AAC1D;;AAEA,YAAY;AACZ;EACE,qBAAqB;EACrB,gCAAgC;AAClC","sourcesContent":[".dropdown-toggle-white-hover {\n  color: #fff !important;\n}\n\n.dropdown-toggle-white-hover:hover,\n.dropdown-toggle-white-hover:focus,\n.dropdown-toggle-white-hover:active {\n  color: #fff !important;\n  background-color: #007bff !important;\n}\n\n.dropdown-toggle-white-hover .icon-hover-white {\n  color: #fff !important;\n}\n\n.dropdown-toggle-white-hover:hover .icon-hover-white,\n.dropdown-toggle-white-hover:focus .icon-hover-white,\n.dropdown-toggle-white-hover:active .icon-hover-white {\n  color: #fff !important;\n}\n\n.custom-scroll::-webkit-scrollbar {\n  width: 8px; /* Largura da barra */\n}\n\n.custom-scroll::-webkit-scrollbar-track {\n  background: #f1f1f1; /* Cor de fundo do trilho */\n  border-radius: 4px; /* Borda arredondada */\n}\n\n/* Cor e estilo da barra */\n.custom-scroll::-webkit-scrollbar-thumb {\n  background: #007bff; /* Cor da barra */\n  border-radius: 4px;\n}\n\n.custom-scroll::-webkit-scrollbar-thumb:hover {\n  background: #0056b3; /* Cor da barra ao passar o mouse */\n}\n\n/* Firefox */\n.custom-scroll {\n  scrollbar-width: thin;\n  scrollbar-color: #007bff #f1f1f1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
