import React, {useState} from "react";
import {NavDropdown, OverlayTrigger, Tooltip} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../../actions/authActions";
import api from "../../../services/axios";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";

const NavDropdownUser = () => {
    const baseURL = window.location.origin;
    const {i18n, t} = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
    const user = useSelector(state => state.auth.user);
    const dispath = useDispatch();
    const navigate = useNavigate();

    const handleLogout = async () => {
        try{
            const resp = await api.get(`/api/${selectedLanguage.toLowerCase()}/private/user/logout`);
            if (resp?.data?.status) {
                dispath(logout());
                navigate("/");
            }
        }catch (e) {
            console.log(e);
        }

    }

    return (
        <NavDropdown className={"px-lg-1"} align={"end"}
                     title={<OverlayTrigger placement={"auto"} overlay={<Tooltip>Dados do Usuário</Tooltip>}>
                             <span><i className={"fs-5 text-primary bi bi-person-circle"}></i> <span
                                 className={"fs-6"}>{user?.name ? user.name : 'Anônimo'}</span></span></OverlayTrigger>}>
            <NavDropdown.Item className={"fs-6"} href={`${baseURL}/profile`}>
                <i className={"me-2 text-primary bi bi-person"}/> Perfil
            </NavDropdown.Item>
            <NavDropdown.Item className={"fs-6"} href={`${baseURL}/secure`}>
                <i className={"me-2 text-primary bi bi-shield"}/> Segurança
            </NavDropdown.Item>
            <NavDropdown.Item className={"fs-6"} href={`${baseURL}/close`}>
                <i className={"me-2 text-primary bi bi-x-circle"}/> Encerrar Conta
            </NavDropdown.Item>
            <NavDropdown.Divider/>
            <NavDropdown.Item className={"fs-6"} onClick={handleLogout}>
                <i className={"me-2 text-primary bi bi-box-arrow-left"}></i> Sair
            </NavDropdown.Item>
        </NavDropdown>
    );
}

export default NavDropdownUser;