import React, {useEffect, useRef, useState} from "react";
import {Button, Card, Form, Image, ListGroup, Modal, Overlay, OverlayTrigger, Popover, Tooltip} from "react-bootstrap";
import {useFormik} from "formik";
import * as Yup from "yup";
import api from "../../../../services/axios";
import {useTranslation} from "react-i18next";
import InputMask from "react-input-mask";
import {selectAddress, clearSelectedAddress} from "../../../../actions/addressActions";
import {useDispatch, useSelector} from "react-redux";
import ListAddresses from "./listAddresses";
import {addAddress, updateAddress} from "../../../../actions/addressActions";

const FormAddress = () => {
    const {i18n, t} = useTranslation();
    const dispatch = useDispatch();

    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
    const [selectedAddressId, setSelectedAddressId] = useState(null);
    const [errors, setErrors] = useState(null);
    const [success, setSuccess] = useState('');

    const selectedAddress = useSelector((state) => state.address.selectedAddress);

    const formik = useFormik({
        initialValues: {
            street: '',
            number: '',
            complement: '',
            neighborhood: '',
            city: '',
            state: '',
            postal_code: '',
            country: 'Brasil', // Valor padrão
            address_proof: null,
        },
        validationSchema: Yup.object({
            street: Yup.string().required('A rua é obrigatória'),
            number: Yup.string().required('O número é obrigatório'),
            complement: Yup.string(),
            neighborhood: Yup.string().required('O bairro é obrigatório'),
            city: Yup.string().required('A cidade é obrigatória'),
            state: Yup.string().max(2, 'Digite o estado com até 2 caracteres').required('O estado é obrigatório'),
            postal_code: Yup.string().matches(/^\d{8}$/, 'O CEP deve ter exatamente 8 dígitos numéricos').required('O CEP é obrigatório'),
            country: Yup.string().required('O país é obrigatório'),
            address_proof: Yup.mixed().required('O comprovante de endereço é obrigatório'),
        }),
        onSubmit: async (values) => {
            setErrors(null);
            setSuccess('');
            const arrErrors = []
            const cleanedPostalCode = values.postal_code.replace(/\D/g, '');
            const cleanedValues = {
                ...values,
                postal_code: cleanedPostalCode,
            };

            const formData = new FormData();
            for (const key in cleanedValues) {
                formData.append(key, values[key]);
            }

            try {
                const {data} = selectedAddressId
                    ? await api.post(`/api/${selectedLanguage.toLowerCase()}/private/user/address/${selectedAddressId}?_method=PUT`, formData)
                    : await api.post(`/api/${selectedLanguage.toLowerCase()}/private/user/address`, formData);

                if (data?.status) {
                    setSuccess('Endereço salvo com sucesso!');
                    setSelectedAddressId(null);
                    formik.resetForm();
                    console.log(data?.address)
                    if (selectedAddressId) {
                        dispatch(updateAddress(data?.address));
                    } else {
                        dispatch(addAddress(data?.address));
                    }
                } else {
                    for (const [key, val] of Object.entries(data?.errors)) {
                        arrErrors.push(val);
                    }
                    setErrors(arrErrors);
                }
            } catch (err) {
                console.log(err);
                arrErrors.push('Erro ao salvar o endereço.');
                setErrors(arrErrors);
            }
        },
    });

    useEffect(() => {
        if (selectedAddress) {
            setSelectedAddressId(selectedAddress.id);
            formik.setValues({
                street: selectedAddress.street || '',
                number: selectedAddress.number || '',
                complement: selectedAddress.complement || '',
                neighborhood: selectedAddress.neighborhood || '',
                city: selectedAddress.city || '',
                state: selectedAddress.state || '',
                postal_code: selectedAddress.postal_code || '',
                country: selectedAddress.country || 'Brasil',
                address_proof: selectedAddress.address_proof||'',
            });
        }
    }, [selectedAddress]);

    return (
        <div>
            <ListAddresses/>
            <Card className={"shadow-sm"}>
                <Card.Header as={'h5'}>
                    <i className={"fs-5 bi bi-geo-alt"}/> Endereço
                </Card.Header>
                <Card.Body>
                    {errors &&
                        <div className="alert alert-danger pb-0 px-0">
                            <ul>{
                                errors.map((item, index) => (
                                    <li key={index} className={"list-unstyled"}>{item}</li>
                                ))
                            }</ul>
                        </div>}
                    {success && <div className="alert alert-success">{success}</div>}

                    <Form onSubmit={formik.handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Rua</Form.Label>
                            <Form.Control
                                type="text"
                                name="street"
                                value={formik.values.street}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={!!formik.errors.street && formik.touched.street}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.street}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Número</Form.Label>
                            <Form.Control
                                type="text"
                                name="number"
                                value={formik.values.number}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={!!formik.errors.number && formik.touched.number}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.number}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Complemento</Form.Label>
                            <Form.Control
                                type="text"
                                name="complement"
                                value={formik.values.complement}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Bairro</Form.Label>
                            <Form.Control
                                type="text"
                                name="neighborhood"
                                value={formik.values.neighborhood}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={!!formik.errors.neighborhood && formik.touched.neighborhood}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.neighborhood}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Cidade</Form.Label>
                            <Form.Control
                                type="text"
                                name="city"
                                value={formik.values.city}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={!!formik.errors.city && formik.touched.city}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.city}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Estado</Form.Label>
                            <Form.Control
                                type="text"
                                name="state"
                                maxLength="2"
                                value={formik.values.state}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={!!formik.errors.state && formik.touched.state}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.state}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>País</Form.Label>
                            <Form.Control
                                type="text"
                                name="country"
                                value={formik.values.country}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={!!formik.errors.country && formik.touched.country}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.country}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>CEP</Form.Label>
                            <Form.Control
                                as={InputMask}
                                mask={"99999-999"}
                                maskPlaceholder={" "}
                                type="text"
                                name="postal_code"
                                value={formik.values.postal_code}
                                onChange={(e) => {
                                    const maskedValue = e.target.value;
                                    const numericValue = maskedValue.replace(/\D/g, '');
                                    formik.setFieldValue('postal_code', numericValue);
                                }}
                                onBlur={(e) => {
                                    const maskedValue = e.target.value;
                                    const numericValue = maskedValue.replace(/\D/g, '');
                                    formik.setFieldValue('postal_code', numericValue);
                                }}
                                isInvalid={!!formik.errors.postal_code && formik.touched.postal_code}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.postal_code}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Comprovante de Endereço</Form.Label>
                            <Form.Control
                                type="file"
                                name="address_proof"
                                onChange={(e) => formik.setFieldValue('address_proof', e.target.files[0])}
                                onBlur={formik.handleBlur}
                                isInvalid={!!formik.errors.address_proof && formik.touched.address_proof}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.address_proof}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            {selectedAddressId ? "Atualizar Endereço" : "Salvar Endereço"}
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    )
}

export default FormAddress;
