import React from "react";
import {Card} from "react-bootstrap";

const FormAccountLevel = () => {
    return (
        <Card>
            <Card.Header>
                <i className={"fs-5 bi bi-diagram-3"}/> Nivel da conta
            </Card.Header>
            <Card.Body>
                Corpo da página de nível da conta
            </Card.Body>
        </Card>
    )
}

export default FormAccountLevel;