import React, {useEffect, useState} from 'react';
import * as formik from "formik";
import * as Yup from 'yup';
import {Button, Col, FloatingLabel, Form, InputGroup, Row, Spinner} from "react-bootstrap";
import Logo from "../../../../logo.svg";
import {useNavigate, useParams} from "react-router-dom";
import {jwtDecode} from "jwt-decode";
import api from "../../../../services/axios";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

const FormForgotWithToken = () => {
    const {Formik} = formik;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const {token} = useParams();
    const dispatch = useDispatch();
    const [tokenExpired, setTokenExpired] = useState(false);
    const [email, setEmail] = useState('');
    const [termsAccepted, setTermsAccepted] = useState('');
    const {i18n, t} = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
    const navigate = useNavigate();


    const initialValues = {
        password: '',
        passwordConfirm: ``
    }

    useEffect(() => {
        if (token) {
            const dataToken = jwtDecode(token);
            setEmail(dataToken.email);
            setTermsAccepted(dataToken.termsAccepted);
            const currentTime = Date.now() / 1000;
            if (dataToken.exp < currentTime) {
                setTokenExpired(true);
            }
        }
    }, [token]);

    if (tokenExpired) {
        return <div>O token expirou. Por favor, solicite um novo token.</div>;
    }
    const handleSignup = async (data) => {
        setIsSubmitting(true);
        try {
            data.email = email;

            const resp = await api.post(`/api/${selectedLanguage.toLowerCase()}/public/auth/change`, data);
            console.log(resp);
            setIsSubmitting(false);
            if(resp?.data?.change){
                navigate("/forgot-password-success");
            }
            //console.log(resp);
        } catch (error) {
            console.error('Erro ao enviar o formulário:', error);
            setIsSubmitting(false);
        }
    }

    const validationSchema = Yup.object().shape({
        password: Yup.string().required('Senha e obrigatório')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])/,
                "A senha deve conter letras maiúsculas e minúsculas"
            )
            .matches(
                /^(?=.*[0-9])/,
                "A senha deve conter numeros"
            )
            .matches(
                /^(?=.*[!@#$%^&*])/,
                "A senha deve conter caracteres especiais"
            )
            .matches(
                /^(?=.{8,})/,
                "A senha deve conter no minimo 8 caracteres"
            ),
        passwordConfirm: Yup.string().required("Confirmacao de senha e obrigatório").oneOf([Yup.ref('password')], 'As senhas estao diferentes')
    });


    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSignup}
        >{({
               values,
               errors,
               touched,
               handleChange,
               handleBlur,
               handleSubmit,
               isSubmitting
           }) => (
            <Form noValidate onSubmit={handleSubmit}
                  className={"p-0 text-start"}>
                <Row>
                    <Col className={"text-center"}>
                        <img className="mb-2" src={Logo} alt={"Logo plataforma RB"} width="150"/>
                    </Col>
                </Row>
                <Row>
                    <Col className={"py-3 text-center"}>
                        <span className={"fs-3 my-3 text-primary font-robotoaur"}>Modifique sua senha</span>
                    </Col>
                </Row>
                <FloatingLabel className={"mb-4"} label={"Senha"}>
                    <Form.Control onChange={handleChange} onBlur={handleBlur} id={"password"} type={"password"}
                                  name={"password"} placeholder={"**********"}
                                  value={values.password}
                                  isInvalid={touched.password && errors.password}
                                  isValid={touched.password && !errors.password}/>
                    <Form.Control.Feedback type="invalid">
                        {errors.password}
                    </Form.Control.Feedback>
                </FloatingLabel>
                <FloatingLabel className={"mb-4"} label={"Confirme sua senha"}>
                    <Form.Control onChange={handleChange} onBlur={handleBlur} id={"passwordConfirm"} type={"password"}
                                  name={"passwordConfirm"} placeholder={"**********"}
                                  value={values.passwordConfirm}
                                  isInvalid={touched.passwordConfirm && errors.passwordConfirm}
                                  isValid={touched.passwordConfirm && !errors.passwordConfirm}/>
                    <Form.Control.Feedback type="invalid">
                        {errors.passwordConfirm}
                    </Form.Control.Feedback>
                </FloatingLabel>
                <Button type={"submit"} style={{padding: "0.7em 0"}} className={"w-100 btn-lg"}
                        variant={"primary"}>Inscrever-se {isSubmitting &&
                    (<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>)}</Button>
            </Form>
        )}
        </Formik>
    );
};

export default FormForgotWithToken;