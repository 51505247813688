const initialState = {
    isAuthenticated: false,
    user: null,
    token: null,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user,
                tokenType: action.payload.tokenType,
                token: action.payload.token,
                refreshToken: action.payload.token,
            };
        case 'LOGOUT':
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                tokenType:null,
                token: null,
                refreshToken: null,
            };
        default:
            return state;
    }
};

export default authReducer;