import React, {useEffect, useState} from 'react';
import api from "../../../../services/axios";
import {useTranslation} from "react-i18next";
import {Alert, Button, Card, Image, ListGroup, Modal, OverlayTrigger, Spinner, Tooltip} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {addAddress, deleteAddress, selectAddress} from "../../../../actions/addressActions";

const ListAddresses = (props) => {
    //const [addresses, setAddresses] = useState([]);
    const [countAddressesVerified, setCountAddressesVerified] = useState(0);
    const [selectedAddressId, setSelectedAddressId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [addressImage, setAddressImage] = useState('');
    const [showModalVerified, setShowModalVerified] = useState(false);
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState(null);
    const [success, setSuccess] = useState('');
    const [verifiedMessage, setVerifiedMessage] = useState('');
    const [verifiedStatus, setVerifiedStatus] = useState('');
    const [stopFetch, setStopFetch] = useState(false);

    const {i18n, t} = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
    const dispatch = useDispatch();

    const addresses = useSelector((state) => state.address.addresses);

    const fetchAddresses = async () => {
        const arrErros = []
        try {
            const {data} = await api.get(`/api/${selectedLanguage.toLowerCase()}/private/user/address`);
            setCountAddressesVerified(data.addresses.filter(address => address.verified == true).length);
            if(data?.addresses?.length >=1){
                data?.addresses?.map(address => {
                    dispatch(addAddress(address));
                });
            }
            setLoading(false);
        } catch (err) {
            arrErros.push(err);
            arrErros.push("Erro ao carregar os endereços.")
            setErrors(arrErros);
            setLoading(false);
        }
    };

    const handleDelete = async (id) => {
        const arrErrors = []
        try {
            const {data} = await api.delete(`/api/${selectedLanguage.toLowerCase()}/private/user/address/${id}`);
            if(data?.status){
                dispatch(deleteAddress(id));
            }else {
                for (const [key, val] of Object.entries(data?.errors)) {
                    arrErrors.push(val);
                }
                setErrors(arrErrors);
                console.log(data);
            }
        } catch (err) {
            console.log(err);
            arrErrors.push(err);
            arrErrors.push("Erro ao excluir o endereço.")
            setErrors(arrErrors);
        }
    };

    const handleSelectAddress = (address) => {
        setSelectedAddressId(address.id);
        dispatch(selectAddress(address));
    };

    const handleShowVerified = async (verified = null, verified_at = null, verification_reason = null) => {
        if (verified == null) {
            setVerifiedMessage('Seu comprovante de endereço esta em verificação...');
        } else if (verified == true) {
            setVerifiedMessage(`Seu comprovante de endereço foi verificado em ${(new Date(verified_at)).toLocaleString()}!`);
        } else {
            setVerifiedMessage(verification_reason);
        }
        setVerifiedStatus(verified);
        setShowModalVerified(true);
    };

    const handleShowImage = async (id) => {
        const arrErrors = []
        try {
            const imageRes = await api.get(`/api/${selectedLanguage.toLowerCase()}/private/user/address/${id}/image`, {
                responseType: 'arraybuffer'
            });

            const contentType = imageRes.headers['content-type'];

            const base64Image = btoa(
                new Uint8Array(imageRes.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
            );
            let imageFormat = '';
            if (contentType.includes('image/png')) {
                imageFormat = 'image/png';
            } else if (contentType.includes('image/jpeg')) {
                imageFormat = 'image/jpeg';
            } else if (contentType.includes('image/svg+xml')) {
                imageFormat = 'image/svg+xml';
            } else if (contentType.includes('application/pdf')) {
                imageFormat = 'application/pdf';
            } else {
                console.error('Formato de imagem não suportado:', contentType);
                return;
            }
            setAddressImage(`data:${imageFormat};base64,${base64Image}`);
            setShowModal(true);
        } catch (err) {
            console.log(err);
            arrErrors.push(err);
            arrErrors.push("Erro ao carregar a imagem.")
            setErrors(arrErrors);
        }
    };

    /*useEffect(() => {
        setAddresses(addressesList);
    }, [addressesList]);*/

    useEffect(() => {
        fetchAddresses();
    }, []);

    if (loading) {
        return (
            <Alert variant={"info"} className={"shadow-sm"}>
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className={"me-2"}
                />
                <strong>Carregando...</strong>
            </Alert>
        );
    }

    return (
        <div>
            <Card className={"mb-3 shadow-sm"}>
                <Card.Header as={'h5'}>
                    <i className={"fs-5 bi bi-geo-alt"}/> Lista de endereços
                </Card.Header>
                <Card.Body className={"p-0"}>
                    {errors &&
                        <div className="alert alert-danger pb-0 px-0 mx-3 mt-3">
                            <ul>{console.log(errors)}{
                                errors?.map((item, index) => (
                                    <li key={index} className={"list-unstyled"}>{item}</li>
                                ))
                            }</ul>
                        </div>}
                    <ListGroup className="mb-3 mt-3 list-group-flush">
                        {addresses.map((address,index) => (
                            <ListGroup.Item key={index}
                                            className={"d-flex justify-content-between align-items-start"}>
                                <div className={"me-auto"}>
                                    <div className={"fw-bold"}>
                                        {address?.street}, {address?.number}
                                    </div>
                                    {address?.city}/{address?.state} -
                                    CEP: {address?.postal_code?.replace(/(\d{5})(\d{3})/, '$1-$2')}
                                </div>
                                <div>
                                    <OverlayTrigger placement={"auto"}
                                                    overlay={<Tooltip>Status da verificação</Tooltip>}>
                                        <Button
                                            variant={address?.verified == null ? "outline-info" : address?.verified == true ? "outline-success" : "outline-danger"}
                                            size="sm" className="ms-1"
                                            onClick={() => handleShowVerified(address?.verified, address?.verified_at, address?.verification_reason)}>
                                            <i className={address?.verified == null ? "bi bi-dash-circle" : address?.verified == true ? "bi bi-check-circle" : "bi bi-x-circle"}/>
                                        </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement={"auto"} overlay={<Tooltip>Ver comprovante</Tooltip>}>
                                        <Button variant="outline-secondary" size="sm" className="ms-1"
                                                onClick={() => handleShowImage(address.id)}><i
                                            className={"bi bi-image"}/></Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement={"auto"} overlay={<Tooltip>Editar</Tooltip>}>
                                        <Button variant="outline-warning" size="sm"
                                                className={address?.verified ? "disabled ms-1" : "ms-1"}
                                                onClick={() => handleSelectAddress(address)}>
                                            <i className={"bi bi-pencil-square"}/>
                                        </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement={"auto"} overlay={<Tooltip>Excluir</Tooltip>}>
                                        <Button variant="outline-danger" size="sm"
                                                className={address?.verified && countAddressesVerified <= 1 ? "disabled ms-1" : "ms-1"}
                                                onClick={() => handleDelete(address?.id)}>
                                            <i className={"bi bi-trash"}/>
                                        </Button>
                                    </OverlayTrigger>

                                </div>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Card.Body>
            </Card>
            <Modal size="lg" show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header className={"py-2"} closeButton>
                    <Modal.Title as={"h5"}><i className={"bi bi-image me-2"}/>Comprovante de
                        Endereço</Modal.Title>
                </Modal.Header>
                <Modal.Body className={"p-0 py-2"}>
                    {addressImage ? <Image
                        src={addressImage}
                        alt="Comprovante de Endereço"
                        style={{width: '100%'}}
                    /> : 'Imagem não disponível.'}
                </Modal.Body>
            </Modal>
            <Modal size="lg" show={showModalVerified} onHide={() => setShowModalVerified(false)}>
                <Modal.Header className={"py-2"} closeButton>
                    <Modal.Title as={"h5"}
                                 className={verifiedStatus == null ? "text-secondary" : verifiedStatus == true ? "text-success" : "text-danger"}>
                        <i className={verifiedStatus == null ? "bi bi-dash-circle me-2" : verifiedStatus == true ? "bi bi-check-circle me-2" : "bi bi-x-circle me-2"}/>Status
                        da verificação
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {verifiedMessage}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ListAddresses;