import { combineReducers } from 'redux';
import signupReducer from "./signupReducer";
import authReducer from "./authReducer";
import offcanvasReducer from "./dashboardReducer";
import {addressReducer} from "./addressReducer";
import themeReducer from "./themeReducer";

const rootReducer = combineReducers({
    signup: signupReducer,
    auth: authReducer,
    offcanvas:offcanvasReducer,
    address: addressReducer,
    theme: themeReducer,
});

export default rootReducer;