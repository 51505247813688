import React from "react";
import {Button, Col, Row} from "react-bootstrap";
import Logo from "../../../../logo.svg";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const PageForgotPasswordSuccess = () => {
    const {t} = useTranslation();
    const name = useSelector(state => state.signup.name);
    return (
        <div>
            <Row>
                <Col className={"text-center"}>
                    <img className={"mb-2"} src={Logo} alt={"Logo ABR"} width={"150"}/><br/>
                </Col>
            </Row>
            <Row>
                <Col className={"text-center py-3"}>
                    <span className={"fs-4 lh-1 text-primary font-robotoaur"}>Senha recuperada com sucesso</span><br/>
                    <span className={"fs-6 lh-1 text-primary font-robotoaur"}>{name}</span><br/>
                </Col>
            </Row>
                <Button href={"/signin"} style={{padding: "0.7em 0"}} className={"w-100 mb-3"}
                        size={"lg"}>Voltar para o Login</Button>
        </div>
    );
}

export default PageForgotPasswordSuccess;