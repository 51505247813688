import React from 'react';
import {Link} from "react-router-dom";
import hash from "../../helpers/crypto";
import {useTranslation} from "react-i18next";

const FooterCopyright = () => {
    const {t} = useTranslation();
    return (
        <div className={"py-1 w-100 text-center"}>
            <p className="text-body-secondary">
                <small>
                    <small>
                        {t(hash("sha256", "Developed by"))} <Link className={"text-decoration-none"} to={"https://rtntecnologia.ddns.net:8080"}>RTN Técnologia</Link> &copy; 2024–2024
                    </small>
                </small>
            </p>
        </div>
    );
};

export default FooterCopyright;
