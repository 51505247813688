import React from 'react';
import {Button, Form} from "react-bootstrap";

const FormIndex = () => {
    const [error, setError] = React.useState(false);
    const [fietCurrency, setLoading] = React.useState(false);
    return (

        <Form>
            {error && <div className="alert alert-danger">{error}</div>}
            <Form.Group className="mb-3">
                <Form.Label>Nome</Form.Label>
                <Form.Control
                    type="text" readOnly disabled
                    value={""}
                />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Sobrenome</Form.Label>
                <Form.Control
                    type="text" readOnly disabled
                    value={""}
                />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                    type="email" readOnly disabled
                    value={""}
                />
            </Form.Group>
            <Button variant="primary" type="submit">
                Atualizar
            </Button>
        </Form>
    );
}

export default FormIndex;