import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./fonts/robotaur.ttf"
import "./fonts.css";
import "./pages/css/form.scss";
import {I18nextProvider} from "react-i18next";
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import {Provider} from "react-redux";
import {store} from "./store";
import "./index.css";

i18n.use(HttpBackend)
    .use(LanguageDetector)
    .init({
        debug: false,
        fallbackLng: 'en',
        defaultNS: ['welcome'],
        keySeparator: false,
        supportedLngs: ['en', 'es', 'fr', 'it', 'pl', 'pt', 'ru', 'th', 'vi', 'tr', 'zh-CN', 'zh-TW', 'bn', 'ar', 'ko', 'de', 'si'],
        detection: {
            order: ['localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
        },
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
        interpolation: {
            escapeValue: false,
        }
    });


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <I18nextProvider i18n={i18n}>
                <App/>
            </I18nextProvider>
        </Provider>
    </React.StrictMode>
);


