import React, {useEffect, useState} from "react";
import {Alert, Button, Card, Form, Spinner} from "react-bootstrap";
import api from "../../../../services/axios";
import {useTranslation} from "react-i18next";
import InputMask from "react-input-mask";

const FormProfile = () => {
    const [userData, setUserData] = useState({
        name: '',
        surname: '',
        email: ''
    });

    const {i18n, t} = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await api.get(`/api/${selectedLanguage.toLowerCase()}/private/user/profile`);
                setUserData(response.data.user);
                setLoading(false);
            } catch (err) {
                setError('Erro ao carregar os dados.');
                setLoading(false);
            }
        };

        fetchProfile();
    }, []);

    if (loading) {
        return (
            <Alert variant={"info"} className={"shadow-sm"}>
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className={"me-2"}
                />
                <strong>Carregando...</strong>
            </Alert>
        );
    }

    return (
        <Card className={"shadow-sm"}>
            <Card.Header>
                <i className={"fs-5 bi bi-person"}/> Perfil
            </Card.Header>
            <Card.Body>
                {error && <div className="alert alert-danger">{error}</div>}
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Nome</Form.Label>
                        <Form.Control
                            type="text" readOnly disabled
                            value={userData.name}
                            onChange={(e) => setUserData({...userData, name: e.target.value})}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Sobrenome</Form.Label>
                        <Form.Control
                            type="text" readOnly disabled
                            value={userData.surname}
                            onChange={(e) => setUserData({...userData, surname: e.target.value})}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email" readOnly disabled
                            value={userData.email}
                            onChange={(e) => setUserData({...userData, email: e.target.value})}
                        />
                    </Form.Group>

                    <Button variant="primary" type="submit">
                        Atualizar
                    </Button>
                </Form>
            </Card.Body>
        </Card>
    )
}

export default FormProfile;