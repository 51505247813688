import {
    SELECT_ADDRESS,
    CLEAR_SELECTED_ADDRESS,
    ADD_ADDRESS,
    UPDATE_ADDRESS,
    DELETE_ADDRESS
} from '../actions/addressActions';

const initialState = {
    addresses: [],
    selectedAddress: null,
};

export const addressReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_ADDRESS:
            const addressExists = state.addresses.some(address => address.id === action.payload.id);
            return {
                ...state,
                addresses: addressExists ? state.addresses : [...state.addresses, action.payload],
            };
        case UPDATE_ADDRESS:
            return {
                ...state,
                addresses: state.addresses.map((address) =>
                    address.id === action.payload.id ? action.payload : address
                ),
            };
        case DELETE_ADDRESS:
            return {
                ...state,
                addresses: state.addresses.filter((address) => address.id !== action.payload),
            };
        case SELECT_ADDRESS:
            return {
                ...state,
                selectedAddress: action.payload
            };
        case CLEAR_SELECTED_ADDRESS:
            return {
                ...state,
                selectedAddress: null
            };
        default:
            return state;
    }
};