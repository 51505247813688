import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import PageProfile from "./components/pageProfile";

const Profile = () => {
    return (
        <div>
            <Container fluid className={"d-flex pt-3 pb-3"}
                       style={{height: 'calc(100vh - 101.5px)'}}>
                <Row className={"w-100 justify-content-center m-0 p-0"}>
                    <Col className={"m-0 p-0"} xs={12} sm={12} md={12} lg={10} xl={8} xxl={8}>
                        <PageProfile/>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Profile;
