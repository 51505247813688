import React, {useEffect, useState} from 'react';
import * as formik from "formik";
import * as Yup from "yup";
import {Alert, Button, Col, FloatingLabel, Form, FormText, Row, Spinner} from "react-bootstrap";
import Logo from "../../../../logo.svg";
import {Link, useNavigate} from "react-router-dom";
import api from "../../../../services/axios";
import {useDispatch} from "react-redux";
import {login} from "../../../../actions/authActions";
import {useTranslation} from "react-i18next";

const FormSignin = () => {
    const {Formik} = formik;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [message, setMessage] = useState('');
    const {i18n, t} = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

    const handleSignin = async (data) => {
        setIsSubmitting(true);
        try {
            const resp = await api.post(`/api/${selectedLanguage.toLowerCase()}/public/auth/login`, data);
            if (resp?.data?.status) {
                setIsSubmitting(false);
                dispatch(login({
                    user: resp.data.data,
                    isAuthenticated: resp.data.isAuthenticated,
                    tokenType: resp.data.dataToken.token_type,
                    token: resp.data.dataToken.access_token,
                    refreshToken: resp.data.dataToken.refresh_token
                }));
                setMessage('')
                navigate("/");
            } else {
                setIsSubmitting(false);
                setMessage(resp?.data?.message);
            }
        } catch (e) {
            console.log(e);
            setIsSubmitting(false);
            setMessage(e?.data?.message);
        }
    }

    const initialValues = {
        email: '',
        password: '',
        remember: false
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string().email("O e-mail é invalido").required('E-mail é obrigatório'),
        password: Yup.string().required("Senha é obrigatória"),
        remember: Yup.bool().notRequired()
    });

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSignin}>
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting
              }) => (
                <Form noValidate onSubmit={handleSubmit} className={"p-0 text-start"}>
                    <Row>
                        <Col className={"text-center"}>
                            <img className="mb-2" src={Logo} alt={"Logo plataforma RB"} height="50"/>
                        </Col>
                    </Row>
                    <Row>
                        <Col className={"py-3 text-center"}>
                            <span className={"fs-3 my-3 text-primary font-robotoaur"}>Entrar</span>
                        </Col>
                    </Row>
                    <FloatingLabel className={"mb-4"} label={"E-mail"}>
                        <Form.Control onChange={e => {
                            handleChange(e);
                            setMessage('');
                        }} onBlur={e => {
                            handleBlur(e);
                            setMessage('');
                        }} id={"email"} type={"email"}
                                      name={"email"}
                                      value={values.email} placeholder={"joao@exemplo.com"}
                                      isInvalid={touched.email && errors.email || message}
                                      isValid={touched.email && !errors.email}/>
                        <Form.Control.Feedback type="invalid">
                            {errors.email}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                    <FloatingLabel className={"mb-4"} label={"Senha"}>
                        <Form.Control onChange={e => {
                            handleChange(e);
                            setMessage('');
                        }} onBlur={e => {
                            handleBlur(e);
                            setMessage('');
                        }} id={"password"} type={"password"}
                                      name={"password"} placeholder={"**********"}
                                      value={values.password}
                                      isInvalid={touched.password && errors.password || message}
                                      isValid={touched.password && !errors.password}/>
                        <Form.Control.Feedback type="invalid">
                            {errors.password}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                    {message && (
                        <Alert variant="danger">
                            {message}
                        </Alert>
                    )}
                    <Row className={"p-0 m-0 mb-3"}>
                        <Col className={"text-start p-0"}>
                            <Form.Check type={"checkbox"} className={"mb-0"}>
                                <div className={"has-validation"}>
                                    <Form.Check.Input className={"me-2"} onChange={handleChange}
                                                      id={"remember"}
                                                      type={"checkbox"}
                                                      name={"remember"}
                                                      value={values.remember}
                                                      isInvalid={touched.remember && errors.remember}
                                                      isValid={touched.remember && !errors.remember}/>
                                    <Form.Check.Label htmlFor={"remember"}>Lembrar-me</Form.Check.Label>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.remember}
                                    </Form.Control.Feedback>
                                </div>
                            </Form.Check>
                        </Col>
                        <Col className={"text-end p-0"}>
                            <FormText><Link className={"text-decoration-none"} to={"/signup"}>Não sou cadastrado!</Link></FormText>
                        </Col>
                    </Row>
                    <Button type={"submit"}
                            style={{padding: "0.7em 0"}}
                            className={"w-100 btn-lg"}
                            variant={"primary"}>Entrar {isSubmitting &&
                        (<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>)}</Button>
                    <Row className={"p-0 m-0"}>
                        <Col className={"text-center p-0 m-0 py-3"}>
                            <Link className={"text-decoration-none"} to={"/forgot"}>Esqueceu a senha?</Link>
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
};

export default FormSignin;
