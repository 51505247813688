import React, {useEffect, useState} from 'react';
import * as formik from "formik";
import * as Yup from 'yup';
import {Button, Col, FloatingLabel, Form, InputGroup, Row, Spinner} from "react-bootstrap";
import Logo from "../../../../logo.svg";
import {useNavigate, useParams} from "react-router-dom";
import {jwtDecode} from "jwt-decode";
import api from "../../../../services/axios";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {setName} from "../../../../actions/signupActions";


const FormSignupWithToken = () => {
    const {Formik} = formik;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const {token} = useParams();
    const dispatch = useDispatch();
    const [tokenExpired, setTokenExpired] = useState(false);
    const [email, setEmail] = useState('');
    const [termsAccepted, setTermsAccepted] = useState('');
    const {i18n, t} = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
    const navigate = useNavigate();


    const initialValues = {
        name: '',
        surname: '',
        day: '',
        month: '',
        year: '',
        password: '',
        passwordConfirm: ``
    }

    useEffect(() => {
        if (token) {
            const dataToken = jwtDecode(token);
            setEmail(dataToken.email);
            setTermsAccepted(dataToken.termsAccepted);
            const currentTime = Date.now() / 1000;
            if (dataToken.exp < currentTime) {
                setTokenExpired(true);
            }
        }
    }, [token]);

    if (tokenExpired) {
        return <div>O token expirou. Por favor, solicite um novo token.</div>;
    }
    const handleSignup = async (data) => {
        setIsSubmitting(true);
        try {
            const birthday = `${data.year}-${data.month}-${data.day}`;
            data.birthday = birthday;
            delete data.year;
            delete data.month;
            delete data.day;

            if(email){
                data.email = email;
            }

            if(termsAccepted){
                data.termsAccepted = termsAccepted;
            }
            const resp = await api.post(`/api/${selectedLanguage.toLowerCase()}/public/auth/signup`, data);
            setIsSubmitting(false);
            if(resp?.data?.created){
                const fullname = `${resp?.data?.name?.trim()} ${resp?.data?.surname?.trim()}`;
                dispatch(setName(fullname?.trim()));
                navigate("/signup-active-success");
            }
            //console.log(resp);
        } catch (error) {
            console.error('Erro ao enviar o formulário:', error);
            setIsSubmitting(false);
        }
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório')
            .min(4,'O nome deve conter mais que 4 caractéres'),
        surname: Yup.string().required('Sobrenome é obrigatório')
            .min(4,'O sobrenome deve conter mais que 4 caractéres'),
        day: Yup.string().required(t('O dia é obrigatório')),
        month: Yup.string().required(t('O mês é obrigatório')),
        year: Yup.string().required(t('O ano é obrigatório')).test('is-18', t('Você deve ter 18 anos ou mais'), function (value) {
            const today = new Date();
            const birthDate = new Date(`${this.parent.year}-${this.parent.month}-${this.parent.day}`);
            const age = today.getFullYear() - birthDate.getFullYear();
            const monthDiff = today.getMonth() - birthDate.getMonth();

            if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
                return age > 18;
            }
            return age >= 18;
        }),
        password: Yup.string().required('Senha e obrigatório')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])/,
                "A senha deve conter letras maiúsculas e minúsculas"
            )
            .matches(
                /^(?=.*[0-9])/,
                "A senha deve conter numeros"
            )
            .matches(
                /^(?=.*[!@#$%^&*])/,
                "A senha deve conter caracteres especiais"
            )
            .matches(
                /^(?=.{8,})/,
                "A senha deve conter no minimo 8 caracteres"
            ),
        passwordConfirm: Yup.string().required("Confirmacao de senha e obrigatório").oneOf([Yup.ref('password')], 'As senhas estao diferentes')
    });

    const renderDateFields = (handleChange, handleBlur, values, touched, errors) => {
        const lang = i18n.language;
        let fieldsOrder;

        // Definir a ordem dos campos de acordo com o idioma
        switch (lang) {
            case 'en':
            case 'de':
                fieldsOrder = ['month', 'day', 'year']; // Mês / Dia / Ano (Estados Unidos, Alemanha)
                break;
            case 'es':
            case 'fr':
            case 'it':
            case 'pt':
            case 'ru':
            case 'zh-CN':
            case 'zh-TW':
            case 'bn':
            case 'ar':
            case 'ko':
            case 'vi':
            case 'th':
            case 'pl':
            case 'tr':
            case 'si':
                fieldsOrder = ['day', 'month', 'year']; // Dia / Mês / Ano (Brasil, Espanha, etc.)
                break;
            default:
                fieldsOrder = ['day', 'month', 'year']; // Padrão
                break;
        }

        return (
            <Row className="mb-4">
                <Col>
                    <InputGroup>
                        {fieldsOrder.map(field => (
                            <React.Fragment key={field}>
                                {field === 'day' && (
                                    <Form.Select style={{padding: '1em 0.8em'}} name="day" onChange={handleChange} onBlur={handleBlur} value={values.day}
                                                 isInvalid={touched.day && errors.year}
                                                 isValid={touched.day && !errors.year}>
                                        <option value="">{t('day')}</option>
                                        {[...Array(31)].map((_, i) => (
                                            <option key={i} value={i + 1<10?`0${i + 1}`:i + 1}>{i + 1<10?`0${i + 1}`:i + 1}</option>
                                        ))}
                                    </Form.Select>
                                )}
                                {field === 'month' && (
                                    <Form.Select style={{padding: '1em 0.8em'}} name="month" onChange={handleChange} onBlur={handleBlur} value={values.month}
                                                 isInvalid={touched.month && errors.year}
                                                 isValid={touched.month && !errors.year}>
                                        <option value="">{t('month')}</option>
                                        {[...Array(12)].map((_, i) => (
                                            <option key={i} value={i + 1<10?`0${i + 1}`:i + 1}>{i + 1<10?`0${i + 1}`:i + 1}</option>
                                        ))}
                                    </Form.Select>
                                )}
                                {field === 'year' && (
                                    <Form.Select style={{padding: '1em 0.8em'}} name="year" onChange={handleChange} onBlur={handleBlur} value={values.year}
                                                 isInvalid={touched.year && errors.year}
                                                 isValid={touched.year && !errors.year}>
                                        <option value="">{t('year')}</option>
                                        {[...Array(100)].map((_, i) => (
                                            <option key={i} value={new Date().getFullYear() - i}>{new Date().getFullYear() - i}</option>
                                        ))}
                                    </Form.Select>
                                )}
                            </React.Fragment>
                        ))}
                    </InputGroup>
                    {errors.year && touched.year && (
                        <div className="invalid-feedback d-block">{errors.year}</div>
                    )}
                </Col>
            </Row>
        );
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSignup}
        >{({
               values,
               errors,
               touched,
               handleChange,
               handleBlur,
               handleSubmit,
               isSubmitting
           }) => (
            <Form noValidate onSubmit={handleSubmit}
                  className={"p-0 text-start"}>
                <Row>
                    <Col className={"text-center"}>
                        <img className="mb-2" src={Logo} alt={"Logo plataforma RB"} width="150"/>
                    </Col>
                </Row>
                <Row>
                    <Col className={"py-3 text-center"}>
                        <span className={"fs-3 my-3 text-primary font-robotoaur"}>Agora falta pouco</span>
                    </Col>
                </Row>
                <FloatingLabel className={"mb-4"} label={"Nome"}>
                    <Form.Control onChange={handleChange} onBlur={handleBlur} id={"name"} type={"name"}
                                  name={"name"} placeholder={"Fulano"}
                                  value={values.name}
                                  isInvalid={touched.name && errors.name}
                                  isValid={touched.name && !errors.name}/>
                    <Form.Control.Feedback type="invalid">
                        {errors.name}
                    </Form.Control.Feedback>
                </FloatingLabel>
                <FloatingLabel className={"mb-4"} label={"Sobrenome"}>
                    <Form.Control onChange={handleChange} onBlur={handleBlur} id={"surname"} type={"surname"}
                                  name={"surname"} placeholder={"de Tal"}
                                  value={values.surname}
                                  isInvalid={touched.surname && errors.surname}
                                  isValid={touched.surname && !errors.surname}/>
                    <Form.Control.Feedback type="invalid">
                        {errors.surname}
                    </Form.Control.Feedback>
                </FloatingLabel>
                {renderDateFields(handleChange, handleBlur, values, touched, errors)}
                <Form.Control.Feedback type="invalid">
                    {errors.name}
                </Form.Control.Feedback>
                <FloatingLabel className={"mb-4"} label={"Senha"}>
                    <Form.Control onChange={handleChange} onBlur={handleBlur} id={"password"} type={"password"}
                                  name={"password"} placeholder={"**********"}
                                  value={values.password}
                                  isInvalid={touched.password && errors.password}
                                  isValid={touched.password && !errors.password}/>
                    <Form.Control.Feedback type="invalid">
                        {errors.password}
                    </Form.Control.Feedback>
                </FloatingLabel>
                <FloatingLabel className={"mb-4"} label={"Confirme sua senha"}>
                    <Form.Control onChange={handleChange} onBlur={handleBlur} id={"passwordConfirm"} type={"password"}
                                  name={"passwordConfirm"} placeholder={"**********"}
                                  value={values.passwordConfirm}
                                  isInvalid={touched.passwordConfirm && errors.passwordConfirm}
                                  isValid={touched.passwordConfirm && !errors.passwordConfirm}/>
                    <Form.Control.Feedback type="invalid">
                        {errors.passwordConfirm}
                    </Form.Control.Feedback>
                </FloatingLabel>
                <Button type={"submit"} style={{padding: "0.7em 0"}} className={"w-100 btn-lg"}
                        variant={"primary"}>Inscrever-se {isSubmitting &&
                    (<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>)}</Button>
            </Form>
        )}
        </Formik>
    );
};

export default FormSignupWithToken;