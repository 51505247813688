import React from "react";
import {Card} from "react-bootstrap";

const FormAddress = () => {
    return (
        <Card>
            <Card.Header>
                <i className={"fs-5 bi bi-file-richtext"}/> Documentos
            </Card.Header>
            <Card.Body>
                Corpo da página de documentos
            </Card.Body>
        </Card>
    )
}

export default FormAddress;