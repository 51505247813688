import React, {useState} from 'react';
import * as formik from "formik";
import * as Yup from 'yup';
import {Button, Col, FloatingLabel, Form, Row, Spinner} from "react-bootstrap";
import Logo from "../../../../logo.svg";
import {Link, useNavigate} from "react-router-dom";
import api from '../../../../services/axios.jsx';
import {useDispatch} from "react-redux";
import {setEmail} from "../../../../actions/signupActions";
import {useTranslation} from "react-i18next";

const FormEmail = () => {
    const dispatch = useDispatch();
    const {Formik} = formik;
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const {i18n} = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
    const handleSignup = async (data) => {
        setIsSubmitting(true);
        try {
            const resp = await api.post(`/api/${selectedLanguage?.toLowerCase()}/public/auth/confirm`, data);
            console.log(resp);
            if (resp?.data?.send) {
                setIsSubmitting(false);
                dispatch(setEmail(data.email))
                navigate("/signup-success");
            }
        } catch (error) {
            setIsSubmitting(false);
            console.error('Erro ao enviar o formulário:', error);
        }
    }

    const initialValues = {
        email: '',
        termsAccepted: false
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string().email("E-mail invalido").required('E-mail obrigatório').test(`checkUniqueEmail`, `Email já cadastrado`, async function (value) {
            try {
                const resp = await api.post(`/api/${selectedLanguage?.toLowerCase()}/public/auth/unique`, {email: value});
                console.log(resp);
                return !resp.data.exists;
            } catch (e) {
                console.log(e);
                return true;
            }
        }),
        termsAccepted: Yup.bool().required("Aceitar as termos é obrigatório").oneOf([true], 'Você deve aceitar os termos'),
    });

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSignup}
            validate={(values) => {
                validationSchema.validate(values).then(() => {
                    setIsFormValid(true);
                }).catch(() => {
                    setIsFormValid(false);
                });
            }}
        >{({
               values,
               errors,
               touched,
               handleChange,
               handleBlur,
               handleSubmit,
               isSubmitting
           }) => (
            <Form noValidate onSubmit={handleSubmit}
                  className={"p-0 text-start"}>
                <Row>
                    <Col className={"text-center"}>
                        <img className="mb-2" src={Logo} alt={"Logo plataforma RB"} width="150"/>
                    </Col>
                </Row>
                <Row>
                    <Col className={"py-3 text-center"}>
                        <span className={"fs-3 my-3 text-primary font-robotoaur"}>Junte-se a nós</span>
                    </Col>
                </Row>
                <FloatingLabel className={"mb-4"} label={"E-mail"}>
                    <Form.Control onChange={handleChange} onBlur={handleBlur} id={"email"} type={"email"}
                                  name={"email"}
                                  value={values.email} placeholder={"joao@exemplo.com"}
                                  isInvalid={touched.email && errors.email}
                                  isValid={touched.email && !errors.email}/>
                    <Form.Control.Feedback type="invalid">
                        {errors.email}
                    </Form.Control.Feedback>
                </FloatingLabel>
                <Form.Check type={"checkbox"} className={"mb-4"}>
                    <div className={"has-validation"}>
                        <Form.Check.Input className={"me-2"} onChange={handleChange} onBlur={handleBlur}
                                          id={"termsAccepted"}
                                          type={"checkbox"}
                                          name={"termsAccepted"}
                                          value={values.termsAccepted}
                                          isInvalid={touched.termsAccepted && errors.termsAccepted}
                                          isValid={touched.termsAccepted && !errors.termsAccepted}/>
                        <Form.Check.Label htmlFor={"termsAccepted"}>Concordo com os <Link
                            className={"text-decoration-none"}
                            to={"/terms"}>termos e
                            condições</Link></Form.Check.Label>
                        <Form.Control.Feedback type="invalid">
                            {errors.termsAccepted}
                        </Form.Control.Feedback>
                    </div>
                </Form.Check>

                <Button type={"submit"} style={{padding: "0.7em"}} className={"w-100 btn-lg"}
                        variant={"primary"} disabled={!isFormValid}>Inscrever-se {isSubmitting &&
                    (<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>)}</Button>
            </Form>
        )}
        </Formik>
    );
};

export default FormEmail;