import React from "react";
import { Container} from "react-bootstrap";
import PageCheckEmail from "./components/pageCheckEmail";

const CheckEmail = () => {
    return (
        <div>
            <Container>
                <PageCheckEmail/>
            </Container>
        </div>
    );
}

export default CheckEmail;