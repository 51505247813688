// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/robotaur.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Robotoaur';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}

.font-robotoaur {
    font-family: "Robotoaur";
    line-height: 1.2;
}`, "",{"version":3,"sources":["webpack://./src/fonts.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,+DAAmD;AACvD;;AAEA;IACI,wBAAwB;IACxB,gBAAgB;AACpB","sourcesContent":["@font-face {\n    font-family: 'Robotoaur';\n    src: url(\"./fonts/robotaur.ttf\") format(\"truetype\");\n}\n\n.font-robotoaur {\n    font-family: \"Robotoaur\";\n    line-height: 1.2;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
