const initialState = {
    isOffcanvasOpen: false,
};

const offcanvasReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'OPEN_OFFCANVAS':
            return {
                ...state,
                isOffcanvasOpen: true,
            };
        case 'CLOSE_OFFCANVAS':
            return {
                ...state,
                isOffcanvasOpen: false,
            };
        default:
            return state;
    }
};

export default offcanvasReducer;