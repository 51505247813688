import React, {useEffect, useState} from 'react';
import {Button, Col, Dropdown, Form, Modal, OverlayTrigger, Placeholder, Row, Spinner, Tooltip} from "react-bootstrap";
import {useTranslation} from 'react-i18next';
import Icon from "../../components/icons";
import api from "../../services/axios";
import {useMediaQuery} from "react-responsive";
import hash from "../../helpers/crypto";
import FormIndex from "./formIndex";
import {useSelector} from "react-redux";

const cryptoData = [
    {
        id: 0,
        name: "Dolar",
        description: "Deriv USD",
        icon_path: "usd",
        symbol: "usd",
        currency_symbol: "$",
        provider: "Deriv",
        provider_symbol: "drv",
        provider_icon_path: "drv"
    },
    {
        id: 1,
        name: "Euro",
        description: "Deriv EUR",
        icon_path: "eur",
        symbol: "eur",
        currency_symbol: "€",
        provider: "Deriv",
        provider_symbol: "drv",
        provider_icon_path: "drv"
    },
    {
        id: 2,
        name: "Pound",
        description: "Deriv GBP",
        icon_path: "gbp",
        symbol: "gbp",
        currency_symbol: "£",
        provider: "Deriv",
        provider_symbol: "drv",
        provider_icon_path: "drv"
    },
    {
        id: 3,
        name: "Real",
        description: "Pix Real",
        icon_path: "brl",
        symbol: "brl",
        currency_symbol: "R$",
        provider: "Pix",
        provider_symbol: "pix",
        provider_icon_path: "pix"
    },
    {
        id: 4,
        name: "Dolar",
        description: "Binance USD",
        icon_path: "usd",
        symbol: "usd",
        currency_symbol: "$",
        provider: "Binance",
        provider_symbol: "bnc",
        provider_icon_path: "bnc"
    },
    {
        id: 5,
        name: "Dolar Tether",
        description: "Deriv tUSDT",
        icon_path: "usdt",
        symbol: "usdt",
        currency_symbol: "tUSDT",
        provider: "Deriv",
        provider_symbol: "drv",
        provider_icon_path: "drv"
    },
    {
        id: 6,
        name: "Dolar Coin",
        description: "Deriv USDC",
        icon_path: "usdc",
        symbol: "usdc",
        currency_symbol: "USDC",
        provider: "Deriv",
        provider_symbol: "drv",
        provider_icon_path: "drv"
    },
    {
        id: 7,
        name: "Dolar Tether",
        description: "Deriv eUSDT",
        icon_path: "usdt",
        symbol: "usdt",
        currency_symbol: "eUSDT",
        provider: "Deriv",
        provider_symbol: "drv",
        provider_icon_path: "drv"
    },
    {
        id: 8,
        name: "Dolar Tether",
        description: "Binance USDT",
        icon_path: "usdt",
        symbol: "usdt",
        currency_symbol: "USDT",
        provider: "Binance",
        provider_symbol: "bnc",
        provider_icon_path: "bnc"
    },
    {
        id: 9,
        name: "Bitcoin",
        description: "Deriv BTC",
        icon_path: "btc",
        symbol: "btc",
        currency_symbol: "BTC",
        provider: "Deriv",
        provider_symbol: "drv",
        provider_icon_path: "drv"
    },
    {
        id: 10,
        name: "Bitcoin",
        description: "Binance BTC",
        icon_path: "btc",
        symbol: "btc",
        currency_symbol: "BTC",
        provider: "Binance",
        provider_symbol: "bnc",
        provider_icon_path: "bnc"
    },
    {
        id: 11,
        name: "Ethereum",
        description: "Deriv ETH",
        icon_path: "eth",
        symbol: "eth",
        currency_symbol: "ETH",
        provider: "Deriv",
        provider_symbol: "drv",
        provider_icon_path: "drv"
    },
    {
        id: 12,
        name: "Ethereum",
        description: "Binance ETH",
        icon_path: "eth",
        symbol: "eth",
        currency_symbol: "ETH",
        provider: "Binance",
        provider_symbol: "bnc",
        provider_icon_path: "bnc"
    },
    {
        id: 13,
        name: "Ethereum Classic",
        description: "Deriv ETC",
        icon_path: "etc",
        symbol: "etc",
        currency_symbol: "ETC",
        provider: "Deriv",
        provider_symbol: "drv",
        provider_icon_path: "drv"
    },
    {
        id: 14,
        name: "Ethereum Classic",
        description: "Binance ETC",
        icon_path: "etc",
        symbol: "etc",
        currency_symbol: "ETC",
        provider: "Binance",
        provider_symbol: "bnc",
        provider_icon_path: "bnc"
    },
    {
        id: 15,
        name: "Tron",
        description: "Binance TRX",
        icon_path: "trx",
        symbol: "trx",
        currency_symbol: "TRX",
        provider: "Binance",
        provider_symbol: "bnc",
        provider_icon_path: "bnc"
    },
    {
        id: 16,
        name: "Polygon",
        description: "Binance MATIC",
        icon_path: "matic",
        symbol: "matic",
        currency_symbol: "MATIC",
        provider: "Binance",
        provider_symbol: "bnc",
        provider_icon_path: "bnc"
    },
];
const PageIndex = () => {
    const {i18n, t} = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
    const isSmallScreen = useMediaQuery({maxWidth: 576});

    const [loading, setLoading] = React.useState(true);
    const [priceBuy, setPriceBuy] = React.useState(0);
    const [priceSell, setPriceSell] = React.useState(0);
    const [amountBuy, setAmountBuy] = React.useState(0);
    const [amountSell, setAmountSell] = React.useState(0);
    const [initalState, setInitalState] = React.useState(false);
    const [currencyOne, setCurrencyOne] = React.useState("USD");
    const [currencyTwo, setCurrencyTwo] = React.useState("BRL");
    const [inputValue, setInputValue] = useState("");

    const [showModalBuy, setShowModalBuy] = useState(false);
    const [showModalSell, setShowModalSell] = useState(false);
    /*const [theme, setTheme] = useState("ligth");*/

    const theme = useSelector((state) => state.theme.theme);

    const [selectedOneCurrency, setSelectedOneCurrency] = useState({
        id: 0,
        name: "Moeda",
        description: "Selecione a moeda",
        icon_path: "slc",
        symbol: "slc",
        provider: "",
        provider_symbol: "",
        provider_icon_path: ""
    });
    const [selectedTwoCurrency, setSelectedTwoCurrency] = useState({
        id: 0,
        name: "Moeda",
        description: "Selecione a moeda",
        icon_path: "slc",
        symbol: "slc",
        provider: "",
        provider_symbol: "",
        provider_icon_path: ""
    });

    const handleSelectOneCurrency = (id) => {
        const selectedCurrency = cryptoData?.find(c => c.id == id);

        setSelectedOneCurrency(selectedCurrency);
        setCurrencyOne(selectedCurrency.symbol.toUpperCase());
        setCurrencyTwo(selectedTwoCurrency.symbol.toUpperCase());
    };

    const handleSelectTwoCurrency = (id) => {
        const selectedCurrency = cryptoData?.find(c => c.id == id);
        setSelectedTwoCurrency(selectedCurrency);
        setCurrencyOne(selectedOneCurrency.symbol.toUpperCase());
        setCurrencyTwo(selectedCurrency.symbol.toUpperCase());
    };

    useEffect(() => {
        console.log(theme);
    }, [theme]);

    useEffect(() => {
        updateCurrencyPair();
    }, [currencyOne, currencyTwo]);

    const updateCurrencyPair = async () => {
        try {
            setLoading(true);
            let price = 0;
            const resp = await api.get(`/api/exchange/prices/${currencyOne}/${currencyTwo}`);
            price = parseFloat(resp?.data?.data?.price);
            console.log(resp);
            const buyPercent = (price * 5) / 100;
            const buyPriceLast = parseFloat((price + buyPercent)?.toFixed(2));
            setPriceBuy(buyPriceLast);


            const sellPercent = (price * 5) / 100;
            const sellPriceLast = parseFloat((price - sellPercent)?.toFixed(2));
            setPriceSell(sellPriceLast);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };


    const handleChangePrice = (e) => {
        let value = e.target.value.replace(/[^0-9.,]/g, '');
        const numericValue = parseFloat(value.replace(',', '.')) || 0;
        setAmountBuy((numericValue * priceBuy).toFixed(2));
        setAmountSell((numericValue * priceSell).toFixed(2));
        setInputValue(`${selectedOneCurrency?.currency_symbol}${value}`);
    }

    const handleShowBuy = () => {
        setShowModalBuy(true);
    };

    const handleShowSell = () => {
        setShowModalSell(true);
    };

    useEffect(() => {
        if (!initalState) {
            const selectedCurrencyOne = cryptoData?.find(c => c.symbol == "usd" && c.provider_symbol == "drv");
            setSelectedOneCurrency(selectedCurrencyOne);
            const selectedCurrencyTwo = cryptoData?.find(c => c.symbol == "brl" && c.provider_symbol == "pix");
            setSelectedTwoCurrency(selectedCurrencyTwo);
            setInitalState(true);
        }
        updateCurrencyPair();
    }, []);

    return (
        <div>
            <Form>
                <Row>
                    <Col className={"d-flex justify-content-center align-items-center"}>
                        {isSmallScreen?(<h5 className={theme=="light"?"text-primary":"text-secondary"}>{t(hash("sha256","SELECT TRADING PAIR BELOW."))}</h5>): (
                            <h3 className={theme=="light"?"text-primary":"text-secondary"}>{t(hash("sha256","SELECT TRADING PAIR BELOW."))}</h3>)}
                    </Col>
                </Row>
                <Row className={"my-4"}>
                    <Col>
                        <Dropdown id={"slcCurrencyOne"} onSelect={handleSelectOneCurrency}>
                            {/*<OverlayTrigger
                            overlay={<Tooltip id={selectedOneCurrency.id}>{selectedOneCurrency.description}</Tooltip>}>*/}
                            <Dropdown.Toggle variant={theme=="light"?"outline-primary":"outline-secondary"} size={isSmallScreen ? "none" : "lg"}
                                             className={"w-100"} disabled={loading?true:false}>
                                {selectedOneCurrency.symbol === "slc" ?
                                    (selectedOneCurrency.name?.toUpperCase()) : (
                                        <div className={"d-inline"}>
                                            <Icon name={selectedOneCurrency.icon_path} size={20}
                                                  className={"me-2 mb-1"}/>
                                            {selectedOneCurrency.symbol?.toUpperCase()}
                                        </div>
                                    )
                                }
                            </Dropdown.Toggle>
                            {/*</OverlayTrigger>*/}
                            <Dropdown.Menu className={"w-100 custom-scroll"} style={{height:"268px",overflowY:"scroll"}}>
                                {cryptoData.map((currency) => (
                                    <OverlayTrigger key={currency.id}
                                                    overlay={<Tooltip id={currency.id}>{currency.description}</Tooltip>}>
                                        <Dropdown.Item eventKey={currency.id}
                                                       disabled={currency.id === selectedTwoCurrency.id || currency.id === selectedOneCurrency.id}
                                                       className={"d-flex justify-content-between"}>
                                            <div className={"d-flex align-items-center"}>
                                                <Icon name={currency.icon_path} size={20}
                                                      className={"me-2 mb-1"}/>
                                                {currency.symbol?.toUpperCase()}
                                            </div>
                                        </Dropdown.Item>
                                    </OverlayTrigger>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col className={"mx-0 px-0"}>
                        {loading?
                            (
                                <Spinner className={"mt-1"} variant={theme=="light"?"primary":"secondary"} animation={"grow"} />
                            )
                            :
                            (
                                <i className={`mx-0 px-0 ${theme=="light"?"text-primary":"text-secondary"} fs-2 bi bi-arrow-left-right`}></i>
                            )
                        }
                    </Col>
                    <Col>
                        <Dropdown id={"slcCurrencyTwo"} onSelect={handleSelectTwoCurrency}>
                            {/*<OverlayTrigger
                            overlay={<Tooltip id={selectedTwoCurrency.id}>{selectedTwoCurrency.description}</Tooltip>}>*/}
                            <Dropdown.Toggle variant={theme=="light"?"outline-primary":"outline-secondary"} size={isSmallScreen ? "none" : "lg"}
                                             className={"w-100"} disabled={loading?true:false}>
                                {selectedTwoCurrency.symbol === "slc" ?
                                    (selectedTwoCurrency.name?.toUpperCase()) : (
                                        <div className={"d-inline"}>
                                            <Icon name={selectedTwoCurrency.icon_path} size={20}
                                                  className={"me-2 mb-1"}/>
                                            {selectedTwoCurrency.symbol?.toUpperCase()}
                                        </div>
                                    )
                                }
                            </Dropdown.Toggle>
                            {/*</OverlayTrigger>*/}
                            <Dropdown.Menu className={"w-100 custom-scroll"} style={{height:"268px",overflowY:"scroll"}}>
                                {cryptoData.map((currency) => (
                                    <OverlayTrigger key={currency.id}
                                                    overlay={<Tooltip id={currency.id}>{currency.description}</Tooltip>}>
                                        <Dropdown.Item eventKey={currency.id}
                                                       disabled={currency.id === selectedOneCurrency.id || currency.id === selectedTwoCurrency.id}
                                                       className={"d-flex justify-content-between"}>
                                            <div className={"d-flex align-items-center"}>
                                                <Icon name={currency.icon_path} size={20} className={"me-2 mb-1"}/>
                                                {currency.symbol?.toUpperCase()}
                                            </div>
                                        </Dropdown.Item>
                                    </OverlayTrigger>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
                <Row>
                    <Col className={"d-flex justify-content-center align-items-center"}>
                        <Form.Label className={isSmallScreen?"fw-medium":"fs-4 fw-medium"} htmlFor={"value"}>{t(hash("sha256","VALUE"))}</Form.Label>
                    </Col>
                </Row>
                <Row>
                    <Col className={"d-flex justify-content-center align-items-center mb-3"}>
                        <Form.Control
                            value={inputValue}
                            onChange={handleChangePrice}
                            size={isSmallScreen ? "none" : "lg"}
                            id={"value"}
                            type="text"
                            name={"value"}
                            placeholder={loading?`${t(hash("sha256", "Loading"))}...`:`${selectedOneCurrency?.currency_symbol}0,00`}
                        />
                        <Form.Control.Feedback type="invalid">
                        </Form.Control.Feedback>
                    </Col>
                </Row>
                <Row>
                    <Col className={"d-flex justify-content-center align-items-center"}>
                        {isSmallScreen?(<h6 className={"text-success"}>{t(hash("sha256", "BUY"))}</h6>): (<h4 className={"text-success"}>{t(hash("sha256", "BUY"))}</h4>)}
                    </Col>
                </Row>
                <Row className={isSmallScreen?"":"fs-5"}>
                    <Col className={"text-end"}>
                        <p className={"text-success"}>{t(hash("sha256", "Price"))}: <strong>
                            {loading ? (
                                    <Placeholder animation={"glow"}>
                                        <Placeholder xs={"1"}/>
                                    </Placeholder>)
                                :
                                (`${selectedTwoCurrency?.currency_symbol}${priceBuy}`)
                            }
                        </strong>
                        </p>
                    </Col>
                    <Col className={"text-start"}>
                        <p className={"text-success"}>{t(hash("sha256", "Amount"))}: <strong>
                            {loading ? (
                                    <Placeholder animation={"glow"}>
                                        <Placeholder xs={"1"}/>
                                    </Placeholder>)
                                :
                                (`${selectedTwoCurrency?.currency_symbol}${amountBuy}`)
                            }
                        </strong>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col className={"d-flex justify-content-center align-items-center"}>
                        {isSmallScreen?(<h6 className={"text-danger"}>{t(hash("sha256", "SELL"))}</h6>): (<h4 className={"text-danger"}>{t(hash("sha256", "SELL"))}</h4>)}
                    </Col>
                </Row>
                <Row className={isSmallScreen?"":"fs-5"}>
                    <Col className={"text-end"}>
                        <p className={"text-danger"}>{t(hash("sha256", "Price"))}: <strong>
                            {loading ? (
                                    <Placeholder animation={"glow"}>
                                        <Placeholder xs={"1"}/>
                                    </Placeholder>)
                                :
                                (`${selectedTwoCurrency?.currency_symbol}${priceSell}`)
                            }
                        </strong>
                        </p>
                    </Col>
                    <Col className={"text-start"}>
                        <p className={"text-danger"}>{t(hash("sha256", "Amount"))}: <strong>
                            {loading ? (
                                    <Placeholder animation={"glow"}>
                                        <Placeholder xs={"1"}/>
                                    </Placeholder>)
                                :
                                (`${selectedTwoCurrency?.currency_symbol}${amountSell}`)
                            }
                        </strong>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col className={"d-flex justify-content-end"}>
                        <Button className={"w-75 px-0"} variant={"outline-success"} onClick={handleShowBuy}
                                size={isSmallScreen ? "none" : "lg"}>{t(hash("sha256", "BUY"))}</Button>
                    </Col>
                    <Col className={"d-flex justify-content-start"}>
                        <Button className={"w-75 px-0"} variant={"outline-danger"} onClick={handleShowSell}
                                size={isSmallScreen ? "none" : "lg"}>{t(hash("sha256", "SELL"))}</Button>
                    </Col>
                </Row>
            </Form>
            <Modal size="lg" centered show={showModalBuy} onHide={() => setShowModalBuy(false)}>
                <Modal.Header className={"py-2"} closeButton>
                    <Modal.Title as={"h5"}
                                 className={"text-secondary"}>
                        Status da verificação
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormIndex/>
                </Modal.Body>
            </Modal>
            <Modal size="lg" centered show={showModalSell} onHide={() => setShowModalSell(false)}>
                <Modal.Header className={"py-2"} closeButton>
                    <Modal.Title as={"h5"}
                                 className={"text-secondary"}>
                        Status da verificação
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormIndex/>
                </Modal.Body>
            </Modal>
        </div>


    );
};

export default PageIndex;
