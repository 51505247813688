import React, {useState} from "react";
import {NavDropdown, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

interface Language {
    i18n: string;
    name: string;
}

const NavDropdownLang: React.FC = () => {
    const {i18n} = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState<string>(i18n.language);

    const handleLanguageChange = (language: string) => {
        setSelectedLanguage(language);
        i18n.changeLanguage(language);
    };

    const languages: Language[] = [
        {i18n: 'en', name: 'english'},
        {i18n: 'es', name: 'español'},
        {i18n: 'fr', name: 'français'},
        {i18n: 'it', name: 'italiano'},
        {i18n: 'pl', name: 'polski'},
        {i18n: 'pt', name: 'português'},
        {i18n: 'ru', name: 'pусский'},
        {i18n: 'th', name: 'thai'},
        {i18n: 'vi', name: 'tiếng'},
        {i18n: 'tr', name: 'việt'},
        {i18n: 'zh-CN', name: '简体中文'},
        {i18n: 'zh-TW', name: '繁體中文'},
        {i18n: 'bn', name: 'বাংলা'},
        {i18n: 'ar', name: 'العربية'},
        {i18n: 'ko', name: '한국어'},
        {i18n: 'de', name: 'deutsch'},
        {i18n: 'si', name: 'සිංහල'}
    ];

    return (
        <OverlayTrigger placement={"auto"} overlay={<Tooltip>Lista de Idiomas</Tooltip>}>
            <NavDropdown className={"ps-1 fs-6"} align={"end"} title={<span><i className={"text-primary bi bi-globe me-2"}></i>{selectedLanguage.toUpperCase()}</span>}>
                {
                    languages.map((language: Language) => (
                        selectedLanguage === language.i18n ? (
                            <NavDropdown.Item active={true}
                                              key={language.i18n}
                                              onClick={() => handleLanguageChange(language.i18n)}
                            >
                                {language.name}
                            </NavDropdown.Item>):(
                            <NavDropdown.Item active={false}
                                              key={language.i18n}
                                              onClick={() => handleLanguageChange(language.i18n)}
                            >
                                {language.name}
                            </NavDropdown.Item>
                        )
                    ))}
            </NavDropdown>
        </OverlayTrigger>
    );
};

export default NavDropdownLang;