import React from 'react';
import ReactIcons from "react-crypto-icons";
import IconDeriv from "./IconDeriv.jsx";
import IconPix from "./IconPix.jsx";
import IconUsd from "./IconUsd.jsx";
import IconUsdt from "./IconUsdt.jsx";
import IconBtc from "./IconBtc.jsx";
import IconEth from "./IconEth.jsx";
import IconEtc from "./IconEtc.jsx";
import IconBnb from "./IconBnb.jsx";
import IconBrl from "./IconBrl.jsx";
import IconBnc from "./IconBnc.jsx";
import IconEur from "./IconEur.jsx";
import IconGbp from "./IconGbp.jsx";
import IconMatic from "./IconMatic.jsx";
import IconTrx from "./IconTrx.jsx";
import IconUsdc from "./IconUsdc.jsx";


const Icon = ({key='', name = '', size = 24, className = ''}) => {
    switch (name) {
        case "drv":
            return (<IconDeriv size={size} className={className}/>);
        case "brl":
            return (<IconBrl size={size} className={className}/>);
        case "pix":
            return (<IconPix size={size} className={className}/>);
        case "bnc":
            return (<IconBnc size={size} className={className}/>);
        case "usd":
            return (<IconUsd size={size} className={className}/>);
        case "eur":
            return (<IconEur size={size} className={className}/>);
        case "gbp":
            return (<IconGbp size={size} className={className}/>);
        case "usdt":
            return (<IconUsdt size={size} className={className}/>);
        case "matic":
            return (<IconMatic size={size} className={className}/>);
        case "trx":
            return (<IconTrx size={size} className={className}/>);
        case "usdc":
            return (<IconUsdc size={size} className={className}/>);
        case "btc":
            return (<IconBtc size={size} className={className}/>);
        case "eth":
            return (<IconEth size={size} className={className}/>);
        case "etc":
            return (<IconEtc size={size} className={className}/>);
        case "bnb":
            return (<IconBnb size={size} className={className}/>);
        default:
            return (<ReactIcons key={key} name={name} size={size} className={className}/>);
    }
}

export default Icon;
