import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Signup from "./pages/public/signup";
import Signin from "./pages/public/signin";
import SignupWithToken from "./pages/public/signupwithtoken";
import SignupSuccess from "./pages/public/signupsuccess";
import CheckEmail from "./pages/public/checkemail";
import {useSelector} from "react-redux";
import PrivateRoute from "./components/privateRoute";
import PublicRoute from "./components/publicRoute";
import Forgot from "./pages/public/forgot";
import SignupActiveSuccess from "./pages/public/signupactivesuccess";
import NavBar from "./pages/components/navBar";
import FooterCopyright from "./pages/components/footerCopyright";
import Profile from "./pages/secure/profile";
import Index from "./pages";

function App() {
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    return (
        <Router>
            <NavBar/>
            <Routes>
                <Route path={"/"} element={<Index/>}/>
                <Route element={<PublicRoute isAllowed={isAuthenticated} redirectPath={"/"}/>}>
                    <Route path={"/signin"} element={<Signin/>}/>
                    <Route path={"/signup"} element={<Signup/>}/>
                    <Route path={"/signup-success"} element={<SignupSuccess/>}/>
                    <Route path={"/check-email"} element={<CheckEmail/>}/>
                    <Route path={"/signup/:token"} element={<SignupWithToken/>}/>
                    <Route path={"/forgot"} element={<Forgot/>}/>
                    <Route path={"/signup-active-success"} element={<SignupActiveSuccess/>}/>
                </Route>
                <Route element={<PrivateRoute isAllowed={isAuthenticated}/>} redirectPath={"/"}/>
                <Route element={<PrivateRoute isAllowed={isAuthenticated}/>}>
                    <Route path={"/profile"} element={<Profile/>}/>
                </Route>
            </Routes>
            <FooterCopyright/>
        </Router>
    );
}

export default App;
