import React from "react";
import {Navigate, Outlet, Route} from "react-router-dom";

const PublicRoute = ({isAllowed, redirectPath = "/home", children}) => {
    if (isAllowed) {
        return <Navigate to={redirectPath} replace/>
    }
    return children ? children : <Outlet/>;
};

export default PublicRoute;