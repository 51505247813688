import React from "react";
import {Row, Col, Button} from "react-bootstrap";
import {Link} from "react-router-dom";

const PageCheckEmail = ()=>{
    return (
        <div>
            <Row>
                <Col>
                    <h5 className={"py-2 text-primary"}>Se você não receber um e-mail nosso em alguns minutos, algumas coisas podem ter acontecido:</h5>
                </Col>
            </Row>
            <Row>
                <Col className={"d-flex flex-row gap-3 align-items-center col-12 col-lg-6"}>
                    <i style={{fontSize: "5em"}} className={"bi bi-envelope-x text-primary"}></i><span>O e-mail está na sua pasta de spam (às vezes as coisas se perdem lá).</span>
                </Col>
                <Col className={"d-flex flex-row gap-3 align-items-center col-12 col-lg-6"}>
                    <i style={{fontSize: "5em"}} className={"bi bi-envelope-exclamation text-primary"}></i><span>O endereço de e-mail que você digitou continha um erro ou erro de digitação (acontece com os melhores de nós).</span>
                </Col>
            </Row>
            <Row>
                <Col className={"d-flex flex-row gap-3 align-items-center col-12 col-lg-6"}>
                    <i style={{fontSize: "5em"}} className={"bi bi-envelope-slash text-primary"}></i><span>Você acidentalmente nos forneceu outro endereço de e-mail (geralmente um endereço comercial ou pessoal, em vez daquele que você quis dizer).</span>
                </Col>
                <Col className={"d-flex flex-row gap-3 align-items-center col-12 col-lg-6"}>
                    <i style={{fontSize: "5em"}} className={"bi bi-envelope-at text-primary "}></i><span>Não podemos entregar o e-mail para este endereço (geralmente devido a firewalls ou filtros).</span>
                </Col>
            </Row>
            <Row>
                <Col className={"align-items-center text-center py-4"}>
                    <Link to={"/signup"}><Button style={{padding: "0.7em"}} size={"lg"}>Digite seu e-mail e tente novamente</Button></Link>
                </Col>
            </Row>
        </div>
    );
}

export default PageCheckEmail;