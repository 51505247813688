import React from "react";
import {Col, Row} from "react-bootstrap";
import Logo from "../../../../logo.svg";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

const PageSignupSuccess = ()=>{
    const email = useSelector(state => state.signup.email);
        return (
            <div>
                <Row>
                    <Col className={"text-center"}>
                        <img className="mb-2" src={Logo} alt={"Logo plataforma RB"} width="100"/>
                    </Col>
                </Row>
                <Row>
                    <Col className={"pt-3 text-center"}>
                        <span className={"fs-4 my-3 text-primary font-robotoaur"}>Verifique seu e-mail</span>
                    </Col>
                </Row>
                <Row>
                    <Col className={"pt-3 text-center"}>
                        <i style={{fontSize: "5em"}} className={"bi bi-envelope-paper"}></i>
                    </Col>
                </Row>
                <Row>
                    <Col className={"pb-3 text-center"}>
                        <p className={"fs-5 my-3"}>Enviamos uma mensagem para {email} com um link para ativar sua conta.</p>
                    </Col>
                </Row>
                <Row>
                    <Col className={"pb-3 text-center"}>
                        <Link to={"/check-email"} className={"fs-5 my-3 text-decoration-none text-primary"}>Não recebeu seu e-mail?</Link>
                    </Col>
                </Row>
            </div>
        );
}

export default PageSignupSuccess;