import axios from "axios";
import {store} from "../store.jsx";
import {login, logout} from "../actions/authActions";

axios.defaults.withCredentials = true;

const api = axios.create({
    baseURL: "https://api.calculadora.rtnwh.com.br",
    withCredentials: true,
});

api.interceptors.request.use(async (config) => {
    try {
        const res = await axios.get('/api/csrf-token', {baseURL: "https://api.calculadora.rtnwh.com.br"});
        console.log(res);
        config.headers['X-CSRF-TOKEN'] = res.data.token;
        return config;
    } catch (e) {
        console.log(e);
    }
}, (error) => console.log(error));

api.interceptors.request.use(async (config) => {
    try {
        const state = store.getState();
        const token = state.auth.token;
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    } catch (e) {
        console.log(e);
    }
}, (error) => {
    console.log(error);
    return Promise.reject(error)
});

api.interceptors.response.use(async (resp) => {
        const originalRequest = resp.config;
        console.log(resp);
        if (resp.response && resp.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const state = store.getState();
                const refreshToken = state.auth.refreshToken;

                if (refreshToken) {
                    const response = await api.post('/api/refresh-token', {"refresh_token": refreshToken});

                    const {access_token, refresh_token} = response.data.dataToken;

                    if (access_token) {
                        store.dispatch(login({
                            ...state.auth,
                            token: access_token,
                            refreshToken: refresh_token || state.auth.refreshToken
                        }));

                        originalRequest.headers['Authorization'] = `Bearer ${access_token}`;
                    }
                }

                return api(originalRequest);
            } catch (e) {
                console.log('Erro ao atualizar o token', e);
                store.dispatch(logout());
                return Promise.reject(e);
            }
        }

        return resp;
    }, async (error) => {
        const originalRequest = error.config;

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                const state = store.getState();
                const refreshToken = state.auth.refreshToken;

                if (refreshToken) {
                    const response = await api.post('/api/refresh-token', {"refresh_token": refreshToken});

                    const {access_token, refresh_token} = response.data.dataToken;

                    if (access_token) {
                        store.dispatch(login({
                            ...state.auth,
                            token: access_token,
                            refreshToken: refresh_token || state.auth.refreshToken
                        }));

                        originalRequest.headers['Authorization'] = `Bearer ${access_token}`;
                    }
                }

                return api(originalRequest);
            } catch (e) {
                console.log('Erro ao atualizar o token', e);
                store.dispatch(logout());
                return Promise.reject(e);
            }
        }
        return Promise.reject(error);
    }
);
/*api.interceptors.request.use(async (config) => {
    try {
        const state = store.getState();
        const token = state.auth.token;
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    } catch (e) {
        console.log(e);
    }
}, async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
            const state = store.getState();
            const refreshToken = state.auth.refreshToken;
            if (refreshToken) {
                const response = await axios.post('/api/refresh-token', {"refresh_token": refreshToken});

                const { access_token, refresh_token } = response.data.dataToken;
                if (access_token) {
                    state.auth.token = access_token;

                    if(refresh_token){
                        state.auth.refreshToken = refresh_token;
                    }

                    store.dispatch(login(state.auth));

                    originalRequest.headers.Authorization = `Bearer ${access_token}`;
                }
            }
            return axios(originalRequest);
        } catch (e) {
            console.log(e);
            return Promise.reject(e);
        }
    }

    return Promise.reject(error);
});*/

export default api;