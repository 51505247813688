export const SELECT_ADDRESS = 'SELECT_ADDRESS';
export const CLEAR_SELECTED_ADDRESS = 'CLEAR_SELECTED_ADDRESS';
export const ADD_ADDRESS = 'ADD_ADDRESS';
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const DELETE_ADDRESS = 'DELETE_ADDRESS';

export const addAddress = (address) => ({
    type: ADD_ADDRESS,
    payload: address,
});

export const updateAddress = (address) => ({
    type: UPDATE_ADDRESS,
    payload: address,
});

export const deleteAddress = (id) => ({
    type: DELETE_ADDRESS,
    payload: id,
});

export const selectAddress = (address) => ({
    type: SELECT_ADDRESS,
    payload: address,
});

export const clearSelectedAddress = () => ({
    type: CLEAR_SELECTED_ADDRESS,
});