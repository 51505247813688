import React, {useEffect, useState} from 'react';
import * as formik from "formik";
import * as Yup from "yup";
import {Alert, Button, Col, FloatingLabel, Form, Row, Spinner} from "react-bootstrap";
import Logo from "../../../../logo.svg";
import {useNavigate} from "react-router-dom";
import api from "../../../../services/axios";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {setEmail} from "../../../../actions/signupActions";

const FormForgot = () => {
    const {Formik} = formik;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [message, setMessage] = useState('');
    const {i18n, t} = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

    const handleSignin = async (data) => {
        setIsSubmitting(true);
        console.log(data);
        try {
            const resp = await api.post(`/api/${selectedLanguage.toLowerCase()}/public/auth/forgot`, data);
            console.log(resp);
            if (resp?.data?.status) {
                setIsSubmitting(false);
                dispatch(setEmail(data.email))
                setMessage('');
                navigate("/forgot-success");
            } else {
                setMessage(resp?.data?.errors?.email[0]);
            }
        } catch (e) {
            setIsSubmitting(false);
            console.log(e)
        }
    }

    const initialValues = {
        email: ''
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string().email("O e-mail é invalido").required('E-mail é obrigatório')
    });

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSignin}>
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting
              }) => (
                <Form noValidate onSubmit={handleSubmit} className={"p-0 text-start"}>
                    <Row>
                        <Col className={"text-center"}>
                            <img className="mb-2" src={Logo} alt={"Logo plataforma RB"} width="150"/>
                        </Col>
                    </Row>
                    <Row>
                        <Col className={"pt-3 text-center"}>
                            <span className={"fs-3 my-3 text-primary font-robotoaur"}>Recuperar sua senha</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col className={"pb-3 text-center"}>
                            <span className={"fs-6 my-3 text-primary font-robotoaur"}>Você vai receber um e-mail com as instruções para recuperar sua senha.</span>
                        </Col>
                    </Row>
                    <FloatingLabel className={"mb-4"} label={"E-mail"}>
                        <Form.Control onChange={e => {
                            handleChange(e);
                            setMessage('');
                        }} onBlur={e => {
                            handleBlur(e);
                            setMessage('');
                        }} id={"email"} type={"email"}
                                      name={"email"}
                                      value={values.email} placeholder={"joao@exemplo.com"}
                                      isInvalid={touched.email && errors.email || message }
                                      isValid={touched.email && !errors.email }/>
                        <Form.Control.Feedback type="invalid">
                            {errors.email}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                    {message && (
                        <Alert variant="danger">
                            {message}
                        </Alert>
                    )}
                    <Button type={"submit"}
                            style={{padding: "0.7em 0"}}
                            className={"w-100 btn-lg mb-4"}
                            variant={"primary"}>Recuperar {isSubmitting &&
                        (<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>)}</Button>
                    <Button href={"/signin"}
                            style={{padding: "0.7em 0"}}
                            className={"w-100 btn-lg"}
                            variant={"outline-primary"}>Voltar para o Login</Button>
                </Form>
            )}
        </Formik>
    );
};

export default FormForgot;
