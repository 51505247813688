import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import PageIndex from "./components/pageIndex";

const Index = () => {
    return (
        <div>
            <Container fluid className={"d-flex px-0 text-center align-items-center"}
                       style={{height: 'calc(100vh - 101.5px)'}}>
                <Row className={"w-100 justify-content-center m-0 p-0"}>
                    <Col className={"m-0"} xs={12} sm={12} md={8} lg={8} xl={6} xxl={6}>
                        <PageIndex/>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Index;
