import React, { useState} from 'react';
import {Button, Container, Nav, Navbar} from "react-bootstrap";
import NavbarLogo from "../../logo.svg";
import {Link, useNavigate} from "react-router-dom";
import NavDropdownUser from "./navbar/dropdownUser";
import {useSelector} from "react-redux";
import ButtonAdministration from "./navbar/buttonAdministration";
import NavDropdownLang from "./navbar/dropdownLang";
import {useTranslation} from "react-i18next";
import hash from "../../helpers/crypto";
import ButtonTheme from "./navbar/buttonTheme";

const NavBar = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const baseURL = window.location.origin;
    const user = useSelector(state => state.auth.user);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

    return (
        <Navbar expand={"lg"} className={"shadow-sm"}>
            <Container className={"py-0 my-0"}>
                <Navbar.Brand className={"p-0 m-0"}>
                    <Link className={"text-decoration-none"} to={`${baseURL}/`}>
                        <img alt={"Logo"} className={"pb-1"} src={NavbarLogo} height={24}/>{' '}
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls={"nav-bar-left"}/>
                <Navbar.Collapse id={"nav-bar-left"} className={"justify-content-end"}>
                    <ButtonTheme />
                    {!isAuthenticated && <NavDropdownLang/>}
                    {!isAuthenticated && <Nav.Link className={"ms-3 py-0 fs-6"} href={"/signin"}><i className={"bi bi-box-arrow-in-right text-primary me-2"}></i>{t(hash("sha256","Sign in"))}</Nav.Link>}
                    {isAuthenticated &&
                        <div>
                            {user?.isAdmin && <ButtonAdministration/>}
                            <NavDropdownUser/>
                        </div>
                    }
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavBar;
